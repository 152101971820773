import { Box, TextField } from "@mui/material";
import { FormikValues } from "formik";

type ContactProps = {
  errors: FormikValues;
  values: FormikValues;
  touched: FormikValues;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void>;
};
const Contact = ({
  errors,
  values,
  handleChange,
  touched,
  handleBlur,
}: ContactProps) => {
  return (
    <>
      <Box py={1}>
        <TextField
          fullWidth
          name="firstName"
          label={"First Name"}
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.firstName}
          helperText={touched.firstName && errors.firstName}
          error={Boolean(touched.firstName && errors.firstName)}
        />
      </Box>
      <Box py={1}>
        <TextField
          fullWidth
          name="lastName"
          label={"Last Name"}
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.lastName}
          helperText={touched.lastName && errors.lastName}
          error={Boolean(touched.lastName && errors.lastName)}
        />
      </Box>
      <Box py={1}>
        <TextField
          fullWidth
          name="phoneNumber"
          label={"Phone Number"}
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phoneNumber}
          helperText={touched.phoneNumber && errors.phoneNumber}
          error={Boolean(touched.phoneNumber && errors.phoneNumber)}
        />
      </Box>
    </>
  );
};
export default Contact;
