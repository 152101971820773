import useHttp from "@fast-ninjas/hooks.use-http";
import useAuthentication from "../authentication/useAuthentication";
import { useCallback, useEffect, useState } from "react";
import { Logger } from "../common/logger";

export default function useCities(apiEndpoint?: string) {
  const [cities, setCities] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const { getAccessToken } = useAuthentication();
  const { post, get, loading, patch } = useHttp();

  const createAccessHeader = useCallback(
    async () => ({
      Authorization: `Bearer ${await getAccessToken()}`,
    }),
    [getAccessToken]
  );

  const fetchCities = useCallback(
    async (country: string = "Ghana") => {
      const authHeader = await createAccessHeader();
      try {
        const result = await get(
          `${apiEndpoint}/api/${country}/cities`,
          authHeader
        );
        setCities(result);
        return result;
      } catch (err) {
        Logger.error("Unable to load city");
      }
      return [];
    },
    [get, apiEndpoint, createAccessHeader]
  );

  const fetchCountries = useCallback(async () => {
    const result = [
      {
        id: 1,
        name: "Ghana",
      },
      {
        id: 2,
        name: "Nigeria",
      },
      {
        id: 3,
        name: "Ivory Coast",
      },
    ];
    setCountries(result);
    return result;
  }, []);

  const createCity = useCallback(
    async (data: any) => {
      const authHeader = await createAccessHeader();
      const country = data.country;
      const response = await post(
        `${apiEndpoint}/api/${country}/cities`,
        data,
        authHeader
      );
      if (!response.ok) throw response.statusText;
      const client = await response.json();
      setCities([...cities, client]);
      return client;
    },
    [createAccessHeader, post, apiEndpoint, cities, setCities]
  );
  const updateCity = useCallback(
    async (data: any, id: any) => {
      const authHeader = await createAccessHeader();
      const country = data.country;
      const response = await patch(
        `${apiEndpoint}/api/${country}/cities/${id}`,
        data,
        authHeader
      );
      if (!response.ok) throw response.statusText;
      const client = await response.json();
      setCities([...cities, client]);
      return client;
    },
    [createAccessHeader, patch, apiEndpoint, cities, setCities]
  );

  useEffect(() => {
    fetchCities().catch(Logger.error);
    fetchCountries().catch(Logger.error);
  }, [fetchCities, fetchCountries]);

  return {
    createCity,
    updateCity,
    processing: loading,
    fetchCities,
    cities,
    countries,
  };
}
