import {
  Box,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import AdminLayout from "../../admin/layout";
import { DataTable } from "@fast-ninjas/admin.data-table";
import { useMemo, useState } from "react";
import { Add, KeyboardArrowDown } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useCities from "../../hooks/useCities";
import config from "../../configLoader";

const tableColumns = [
  { field: "country", title: "Country" },
  { field: "name", title: "City" },
];

const formatCitiesForTableDisplay = (cities: any[]) =>
  cities.map((c) => ({
    id: c?.id,
    country: c?.country,
    name: c?.name,
  }));

const Cities = () => {
  const navigateTo = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [country, setCountry] = useState<string>("Ghana");
  const [searchResult, setSearchResult] = useState<Array<string>>([]);
  const { cities, processing, countries, fetchCities } = useCities(
    config.apiEndpoint
  );

  const search = (value?: string) => {
    if (!value || value === "") {
      setSearchResult(cities);
      return;
    }
    setSearchTerm(value || "");
    const data = cities.filter((res) => {
      return JSON.stringify(res)
        .toLocaleLowerCase()
        .match(searchTerm.toLocaleLowerCase());
    });
    if (data) {
      setSearchResult(data as Array<any>);
    }
  };

  const flattenedList = useMemo(() => {
    if (searchTerm && searchResult.length === 0) return [];
    return formatCitiesForTableDisplay(
      searchResult.length > 0 ? searchResult : cities
    );
  }, [cities, searchResult, searchTerm]);

  const handleChangeCountry = (val: string) => {
    setCountry(val);
    fetchCities(val);
  };

  return (
    <AdminLayout title="Cities">
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box width={"50%"} display={"flex"} gap={1}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <Autocomplete
              onInputChange={(_event, newOptions, reason) => {
                if (reason === "clear") {
                  search();
                }
              }}
              freeSolo
              id="combo-box-demo"
              options={[]}
              sx={{ width: 300 }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="search"
                  label="Search"
                  name="search"
                  autoComplete="search"
                  autoFocus
                  value={searchTerm}
                  size="small"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    search(e.target.value)
                  }
                />
              )}
            />
          </Box>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={1}
        >
          <TextField
            select
            fullWidth
            name="country"
            label={"Country"}
            variant="outlined"
            placeholder={"Select a country"}
            SelectProps={{
              native: true,
              IconComponent: KeyboardArrowDown,
            }}
            onChange={(e) => handleChangeCountry(e.target.value)}
            value={country}
            size="small"
          >
            <option value=""></option>
            {countries.map((country, index) => (
              <option key={index} value={country.name}>
                {country.name}
              </option>
            ))}
          </TextField>
          <Button
            color="primary"
            variant="contained"
            endIcon={<Add />}
            onClick={() => navigateTo("/cities/add")}
          >
            Add
          </Button>
        </Box>
      </Box>
      <DataTable
        data={flattenedList}
        columns={tableColumns}
        onRowClicked={(row) => navigateTo("/cities/update", { state: row })}
        emptyDisplay={processing ? <CircularProgress /> : undefined}
      />
    </AdminLayout>
  );
};

export default Cities;
