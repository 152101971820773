import * as Yup from "yup";
import { useFormikWizard } from "formik-wizard-form";
import ClientsFormStepper from "../stepper";
import AdminLayout from "../../admin/layout";
import { Box, Button, Card, Grid } from "@mui/material";
import Contact from "../forms/contact";
import Business from "../forms/business";
import { useRef, useState } from "react";
import CashOut from "../forms/cashout";
import { FormikValues } from "formik";
import useClients from "../../hooks/useClients";
import ProgressButton from "../../components/button";
import { useLocation } from "react-router-dom";
import Alert from "../../components/alert";
import config from "../../configLoader";

const phoneRegExp = /\d{10}$/;
const domainRegex = /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/;

const CreateClient = () => {
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<"success" | "error">("success");
  const alertDialogRef = useRef<any>(null);
  const { state } = useLocation();
  const { createClient, processing, updateClient } = useClients(
    config.apiEndpoint
  );

  const [stepNo, setStepNo] = useState<number>(0);
  const steps: any = [
    {
      component: Contact,
      initialValues: {
        firstName: state?.firstName || "",
        lastName: state?.lastName || "",
        phoneNumber: state?.phoneNumber || "",
      },

      validationSchema: Yup.object().shape({
        firstName: Yup.string().min(1).required("first name is required"),
        lastName: Yup.string().min(1).required("last name is required"),
        phoneNumber: Yup.string()
          .matches(phoneRegExp, "Phone number is not valid")
          .min(10, "too short")
          .required("phone number is required"),
      }),
    },
    {
      component: Business,
      initialValues: {
        name: state?.name || "",
        subdomain: state?.subdomain || "",
        address: state?.address || "",
        city: state?.city || "",
        country: state?.country || "",
        phoneNumber: state?.phoneNumber || "",
      },
      validationSchema: Yup.object().shape({
        isRegistered: Yup.bool(),
        city: Yup.string().min(1).required("city is required"),
        country: Yup.string().min(1).required("country is required"),
        address: Yup.string().min(1).required("address is required"),
        subdomain: Yup.string()
          .matches(domainRegex, "No spaces and no special characters except -")
          .min(2),
        name: Yup.string()
          .min(3, "min characters is 3")
          .required("name is required"),
        phoneNumber: Yup.string()
          .matches(phoneRegExp, "Phone number is not valid")
          .min(10, "too short")
          .required("phone number is required"),
      }),
    },
    {
      component: CashOut,
      initialValues: {
        accountNumber: "",
        accountName: "",
        provider: "",
      },
      validationSchema: Yup.object().shape({
        accountNumber: Yup.string(),
        accountName: Yup.string(),
        provider: Yup.string(),
      }),
    },
  ];
  let initialValues = {
    firstName: state?.firstName || "",
    lastName: state?.lastName || "",
    phoneNumber: state?.phoneNumber || "",
    name: state?.name || "",
    subdomain: state?.subdomain || "",
    address: state?.address || "",
    city: state?.city || "",
    country: state?.country || "",
    accountNumber: state?.accountNumber || "",
    accountName: state?.accountName || "",
    provider: state?.provider || "",
  }; //steps[stepNo]?.initialValues;
  let initialValidationSchema = steps[stepNo]?.validationSchema;
  const {
    renderComponent,
    handlePrev,
    handleNext,
    isNextDisabled,
    isPrevDisabled,
    isLastStep,
    isFirstStep,
    currentStepIndex,
    handleSubmit,
  } = useFormikWizard({
    initialValues: initialValues,
    onSubmit: (formValues: FormikValues) => {
      const postData = {
        business: {
          country: formValues.country,
          address: formValues.address,
          phoneNumber: formValues.phoneNumber,
          city: formValues.city,
          name: formValues.name,
          subdomain: formValues.subdomain,
        },
        contact: {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          phoneNumber: formValues.phoneNumber,
        },
        cashout: {
          accountNumber: formValues.accountNumber,
          accountName: formValues.accountName,
          provider: formValues.provider,
        },
      };
      state ? handleUpdateClient(postData) : handleCreateClient(postData);
    },
    validateOnNext: true,
    activeStepIndex: 0,
    steps: steps,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: false,
    validationSchema: initialValidationSchema,
  });

  const handleCreateClient = async (formValues: any) => {
    try {
      await createClient(formValues);

      openAlertDialog("Client has been created successfully!", "success");
    } catch (error) {
      openAlertDialog("Something went wrong!", "error");
    }
  };
  const handleUpdateClient = async (formValues: any) => {
    try {
      await updateClient({ ...formValues, id: state.id }, state.id);
      openAlertDialog("Client has been updated successfully!", "success");
    } catch (error) {
      openAlertDialog("Something went wrong!", "error");
    }
  };

  const handleNextStep = () => {
    handleNext();
    setStepNo(currentStepIndex + 1);
  };
  const handlePrevStep = () => {
    handlePrev();
    setStepNo(currentStepIndex - 1);
  };

  const openAlertDialog = (message: string, type: "success" | "error") => {
    setAlertMessage(message);
    setAlertType(type);
    alertDialogRef.current.showAlert();
  };

  return (
    <>
      <AdminLayout
        title={state ? "Update Client" : "Add Client"}
        allowedRoles={["ManageClients", "SystemAdmin"]}
      >
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item lg={6} md={8} sm={8} xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card
                  sx={{
                    padding: 3,
                  }}
                >
                  <Box my={3} maxWidth={700}>
                    <ClientsFormStepper activeStep={currentStepIndex} />
                  </Box>
                  <form onSubmit={handleSubmit}>{renderComponent()}</form>
                  <Box display="flex" justifyContent="space-between">
                    {!isFirstStep ? (
                      <Button
                        variant="outlined"
                        disabled={isPrevDisabled}
                        onClick={handlePrevStep}
                      >
                        Previous
                      </Button>
                    ) : (
                      <div></div>
                    )}
                    <ProgressButton
                      isBusy={processing}
                      variant="contained"
                      disabled={isNextDisabled}
                      onClick={handleNextStep}
                    >
                      {isLastStep ? (state ? "Update" : "Finish") : "Next"}
                    </ProgressButton>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Alert
          text={alertMessage}
          ref={alertDialogRef}
          type={alertType}
          goTo="/clients"
        />
      </AdminLayout>
    </>
  );
};

export default CreateClient;
