import { createBrowserRouter } from "react-router-dom";
import {
  ForgotPasswordPage,
  LoginPage,
  SetPasswordPage,
} from "./authentication/pages";
import Dashboard from "./admin";
import Clients from "./clients/pages/clients";
import CreateClient from "./clients/pages/createClient";
import Cities from "./cities/form/cities";
import CreateCity from "./cities/form/createCity";
import ActivitiesPage from "./activity";
import { DeliverySetupPage } from "./delivery/setup";

const router = createBrowserRouter([
  { path: "/", element: <LoginPage /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/forgot-password", element: <ForgotPasswordPage /> },
  { path: "/set-password", element: <SetPasswordPage /> },
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/clients", element: <Clients /> },
  { path: "/clients/add", element: <CreateClient /> },
  { path: "/clients/update/", element: <CreateClient /> },
  { path: "/clients/:clientId/delivery/setup", element: <DeliverySetupPage/> },
  { path: "/cities", element: <Cities /> },
  { path: "/cities/add", element: <CreateCity /> },
  { path: "/cities/update", element: <CreateCity /> },
  { path: "/system/events", element: <ActivitiesPage /> },
  { path: "*", element: <h1>Not Found</h1>}
]);

export default router;
