import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type AlertProps = {
  type: "success" | "error";
  text: string;
  goTo: string;
};

function Alert({ type, text, goTo }: AlertProps, ref: any) {
  const navigateTo = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleNavigateHome = () => {
    handleClose();
    navigateTo(goTo);
  };

  React.useImperativeHandle(ref, () => ({
    showAlert: () => handleOpen(),
    close: () => handleClose(),
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box textAlign={"center"}>
            {type === "success" ? (
              <CheckCircleIcon color="success" sx={{ fontSize: 80 }} />
            ) : (
              <ErrorRoundedIcon color="error" sx={{ fontSize: 80 }} />
            )}
          </Box>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center" }}
          >
            {text}
          </Typography>
          <Box
            sx={{
              justifyContent: "flex-end",
              alignItems: "end",
              width: "100%",
              display: "flex",
              mt: 3,
            }}
          >
            <Button
              onClick={type === "success" ? handleNavigateHome : handleClose}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default React.forwardRef(Alert);
