import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, TextField } from "@mui/material";
import { FormikValues } from "formik";
import useCities from "../../hooks/useCities";
import config from "../../configLoader";
import { ChangeEvent, useEffect } from "react";

type BusinessProps = {
  errors: FormikValues;
  values: FormikValues;
  touched: FormikValues;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void>;
};
const Business = ({
  errors,
  values,
  handleChange,
  touched,
  handleBlur,
  setFieldValue,
}: BusinessProps) => {
  const { fetchCities, countries, cities } = useCities(config.apiEndpoint);

  const handleChangeCountry = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setFieldValue("country", val);
    fetchCities(val);
  };

  useEffect(() => {
    if (values?.country) {
      fetchCities(values.country);
    }
  }, [fetchCities, values]);

  return (
    <>
      <Box py={1}>
        <TextField
          fullWidth
          name="name"
          label={"Name"}
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          helperText={touched.name && errors.name}
          error={Boolean(touched.name && errors.name)}
        />
      </Box>
      <Box py={1}>
        <TextField
          fullWidth
          name="subdomain"
          label={"Subdomain"}
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.subdomain}
          helperText={touched.subdomain && errors.subdomain}
          error={Boolean(touched.subdomain && errors.subdomain)}
        />
      </Box>
      <Box py={1}>
        <TextField
          multiline
          rows={2}
          fullWidth
          name="address"
          label={"Address"}
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.address}
          helperText={touched.address && errors.address}
          error={Boolean(touched.address && errors.address)}
        />
      </Box>
      <Box py={1}>
        <TextField
          fullWidth
          name="phoneNumber"
          label={"Phone Number"}
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phoneNumber}
          helperText={touched.phoneNumber && errors.phoneNumber}
          error={Boolean(touched.phoneNumber && errors.phoneNumber)}
        />
      </Box>
      <Box py={1}>
        <TextField
          select
          fullWidth
          name={"country"}
          label={"Country"}
          variant="outlined"
          placeholder={"Select a country"}
          SelectProps={{
            native: true,
            IconComponent: KeyboardArrowDown,
          }}
          onChange={handleChangeCountry}
          onBlur={handleBlur}
          value={values.country}
          helperText={touched.country && errors.country}
          error={Boolean(touched.country && errors.country)}
        >
          <option value=""></option>
          {countries.map((country, index) => (
            <option key={country?.name} value={country.name}>
              {country.name}
            </option>
          ))}
        </TextField>
      </Box>
      <Box py={1}>
        <TextField
          select
          fullWidth
          name={"city"}
          label={"City"}
          variant="outlined"
          placeholder={"Select a city"}
          SelectProps={{
            native: true,
            IconComponent: KeyboardArrowDown,
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.city}
          helperText={touched.city && errors.city}
          error={Boolean(touched.city && errors.city)}
          disabled={!values.country}
        >
          <option value=""></option>
          {cities?.map((city: any, index: number) => (
            <option key={city?.name} value={city?.name}>
              {city?.name}
            </option>
          ))}
        </TextField>
      </Box>
    </>
  );
};
export default Business;
