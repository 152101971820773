import { Box, Card, TextField, styled } from "@mui/material";
import AdminLayout from "../../admin/layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { KeyboardArrowDown } from "@mui/icons-material";
import useCities from "../../hooks/useCities";
import config from "../../configLoader";
import { useLocation } from "react-router-dom";
import Alert from "../../components/alert";
import { useRef, useState } from "react";
import ProgressButton from "../../components/button";
import { Stack } from "@mui/system";

const validationSchema = Yup.object().shape({
  country: Yup.string().min(1).required("Country is required"),
  city: Yup.string().min(1).required("City is required"),
});

const PageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    width: "60%",
    display: "flex",
  },
}));

export default function CreateCity() {
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<"success" | "error">("success");
  const alertDialogRef = useRef<any>(null);
  const { state } = useLocation();
  const { countries, createCity, processing, updateCity } = useCities(
    config.apiEndpoint
  );
  const formik = useFormik({
    initialValues: {
      country: (state?.country as string) || "",
      city: (state?.name as string) || "",
    },
    onSubmit: (values) => {
      const postData = {
        name: values.city,
        country: values.country,
      };
      state ? handleUpdateCity(postData) : handleCreateCity(postData);
    },
    validationSchema: validationSchema,
  });

  const handleCreateCity = async (formValues: any) => {
    try {
      await createCity(formValues);

      openAlertDialog("City has been created successfully!", "success");
    } catch (error) {
      openAlertDialog("Something went wrong!", "error");
    }
  };

  const handleUpdateCity = async (formValues: any) => {
    try {
      await updateCity({ ...formValues }, state.id);

      openAlertDialog("City has been updated successfully!", "success");
    } catch (error) {
      openAlertDialog("Something went wrong!", "error");
    }
  };

  const openAlertDialog = (message: string, type: "success" | "error") => {
    setAlertMessage(message);
    setAlertType(type);
    alertDialogRef.current.showAlert();
  };

  return (
    <>
      <AdminLayout
        title={state ? "Update City" : "Add City"}
        allowedRoles={["SystemAdmin"]}
      >
        <Stack alignItems="center">
          <PageContainer>
            {CityForm(formik, countries, processing)}
          </PageContainer>
        </Stack>
        <Alert
          text={alertMessage}
          ref={alertDialogRef}
          type={alertType}
          goTo="/cities"
        />
      </AdminLayout>
    </>
  );
}

function CityForm(formik: any, countries: any[], processing: boolean) {
  return (
    <Card
      sx={{
        padding: 3,
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box py={1}>
          <TextField
            select
            fullWidth
            name="country"
            label={"Country"}
            variant="outlined"
            placeholder={"Select a country"}
            SelectProps={{
              native: true,
              IconComponent: KeyboardArrowDown,
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.country}
            helperText={formik?.touched?.country && formik?.errors?.country}
            error={Boolean(formik.touched.country && formik.errors.country)}
          >
            <option value=""></option>
            {countries.map((country, index) => (
              <option key={index} value={country.name}>
                {country.name}
              </option>
            ))}
          </TextField>
        </Box>
        <Box py={1}>
          <TextField
            fullWidth
            name="city"
            label={"City"}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
            helperText={formik.touched.city && formik.errors.city}
            error={Boolean(formik.touched.city && formik.errors.city)}
          />
        </Box>
        <Box py={1}>
          <ProgressButton
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
            isBusy={processing}
          >
            Submit
          </ProgressButton>
        </Box>
      </form>
    </Card>
  );
}
