import useHttp from "@fast-ninjas/hooks.use-http";
import useAuthentication from "../authentication/useAuthentication";
import { useCallback, useEffect, useState } from "react";
import { Logger } from "../common/logger";

export default function useClients(apiEndpoint?: string) {
  const [clients, setClients] = useState<any[]>([]);
  const { getAccessToken, user } = useAuthentication();
  const { post, get, loading, patch } = useHttp();

  const createAccessHeader = useCallback(
    async () => ({
      Authorization: `Bearer ${await getAccessToken()}`,
    }),
    [getAccessToken]
  );

  const fetchClients = useCallback(
    async (city?: string) => {
      const authHeader = await createAccessHeader();
      try {
        const result = await get(
          `${apiEndpoint}/api/accounts?city=${city ?? ""}`,
          authHeader
        );
        setClients(result);
        return result;
      } catch (err) {
        Logger.error("Unable to load client", user);
      }
      return [];
    },
    [get, apiEndpoint, user, createAccessHeader]
  );

  const createClient = useCallback(
    async (data: any) => {
      const authHeader = await createAccessHeader();
      const response = await post(
        `${apiEndpoint}/api/accounts`,
        data,
        authHeader
      );
      if (!response.ok) throw response.statusText;
      const client = await response.json();
      setClients([...clients, client]);
      return client;
    },
    [createAccessHeader, post, apiEndpoint, clients, setClients]
  );
  const updateClient = useCallback(
    async (data: any, id: any) => {
      const authHeader = await createAccessHeader();
      const response = await patch(
        `${apiEndpoint}/api/accounts/${id}`,
        data,
        authHeader
      );
      if (!response.ok) throw response.statusText;
      const client = await response.json();
      setClients([...clients, client]);
      return client;
    },
    [createAccessHeader, patch, apiEndpoint, clients, setClients]
  );

  useEffect(() => {
    fetchClients()
      .then((ps) => setClients(ps))
      .catch(Logger.error);
  }, [fetchClients, setClients]);

  return {
    createClient,
    updateClient,
    processing: loading,
    fetchClients,
    clients,
  };
}
