import useHttp from "@fast-ninjas/hooks.use-http";
import { useCallback, useState } from "react";
import config from "../configLoader";
import useAuthentication from "../authentication/useAuthentication";

export default function useEvents() {
  const { get, loading } = useHttp();
  const [events, setEvents] = useState([]);
  const { getAccessToken } = useAuthentication();

  const createAccessHeader = useCallback(
    async () => ({
      Authorization: `Bearer ${await getAccessToken()}`,
    }),
    [getAccessToken]
  );

  const loadEvents = useCallback(
    async (appId: string, eventType: string = "error") => {
      const authHeader = await createAccessHeader();
      const data = await get(
        `${config.apiEndpoint}/api/${appId}/events?eventType=${eventType}`,
        authHeader
      );
      setEvents(data);
      return data;
    },
    [setEvents, get, createAccessHeader]
  );

  return {
    loading,
    events,
    loadEvents,
  };
}
