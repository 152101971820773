import {
  Box,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
  Link,
  TooltipProps,
  Tooltip,
  styled,
  tooltipClasses,
  Stack,
} from "@mui/material";
import AdminLayout from "../../admin/layout";
import { DataTable, Column } from "@fast-ninjas/admin.data-table";
import { useCallback, useMemo, useState } from "react";
import {
  Add,
  CheckBox,
  DeliveryDining,
  Error,
  KeyboardArrowDown,
  Settings,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useClients from "../../hooks/useClients";
import config from "../../configLoader";
import useCities from "../../hooks/useCities";
import Currency from "../../components/currency";
import { MenuButton } from "../../components/button";

const tableColumns: Column[] = [
  { field: "name", title: "Client" },
  { field: "city", title: "City" },
  { field: "today", title: "Today", render: renderStatsCell },
  { field: "wtd", title: "WTD", render: renderStatsCell },
  { field: "mtd", title: "MTD", render: renderStatsCell },
  { field: "ytd", title: "YTD", render: renderStatsCell },
  { field: "allTime", title: "All Time", render: renderStatsCell },
  { field: "domain", title: "Address", render: rederDomainCell },
  { field: "", title: "", render: renderActionsCell },
];

function renderActionsCell(data: any) {
  if (!data) return <></>;

  const onClick = (e: any) => {
    e.stopPropagation();
  };

  const url = `/clients/${data.id}/delivery/setup`;
  const menuItems = [{
    text: "Delivery Setup",
    icon: <DeliveryDining />,
    onClick: () => {window.location.href = url}
  }]

  return (
    <>
      <div onClick={onClick}>
        <MenuButton icon={<Settings />} menuItems={menuItems}>
          <></>
        </MenuButton>
      </div>
    </>
  );
}

function renderStatsCell(data: any) {
  if (!data) return <></>;
  const { failedCount, collectedAmount, offlineAmount, numOrders, netFees } =
    data;

  const statsValues = [netFees, offlineAmount, numOrders, failedCount];
  const statsLabels = ["Fees paid", "Cash", "# Orders", "# Failed"];
  const statsSymbols = [undefined, undefined, "", ""];
  const toolTipContent = (
    <Stack direction="column">
      {statsValues.map((v, i) => (
        <Stack
          direction="row"
          key={i}
          justifyContent="space-between"
          width={100}
        >
          <Box>{statsLabels[i]}</Box>
          <Box>
            <Currency amount={v} symbol={statsSymbols[i]} />
          </Box>
        </Stack>
      ))}
    </Stack>
  );

  return (
    <HtmlTooltip title={toolTipContent}>
      <Button variant="text">
        <Currency amount={collectedAmount} variant="medium" />
      </Button>
    </HtmlTooltip>
  );
}

function rederDomainCell(data: any) {
  const hasDomain = data?.domainName && data?.domainStatus === "Complete";
  const isDomainError = data?.domainStatus === "Error";
  const domainError = isDomainError && (data?.domainStatusMessage ?? "Error");

  if (!hasDomain && !domainError) return <span>No Domain</span>;
  if (domainError) {
    return (
      <Box display="flex" gap={1}>
        <Box>{domainError}</Box>
        <Box>
          <Error fontSize="small" color="error" />
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" gap={1} onClick={(e) => e.stopPropagation()}>
      <Box>
        <Link target="_blank" href={`https://${data?.domainName}`}>
          {data?.domainName}
        </Link>
      </Box>
      <Box>
        <CheckBox fontSize="small" color="success" />
      </Box>
    </Box>
  );
}

const formatClientsForTableDisplay = (clients: any[]) =>
  clients.map((c) => ({
    phoneNumber: c?.contact?.phoneNumber,
    name: c?.business?.name,
    address: c?.business?.address,
    city: c?.business?.city,
    subdomain: c?.business?.subdomain,
    domain: c?.business,
    today: c?.stats?.today,
    wtd: c?.stats?.wtd,
    mtd: c?.stats?.mtd,
    ytd: c?.stats?.ytd,
    allTime: c?.stats?.allTime,
    id: c?.id,
  }));

const Clients = () => {
  const navigateTo = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { clients, processing, fetchClients } = useClients(config.apiEndpoint);
  const { cities } = useCities(config.apiEndpoint);
  const flattenedList = useMemo(() => {
    return formatClientsForTableDisplay(clients);
  }, [clients]);

  const filteredClients = useMemo(() => {
    if (!searchTerm) return flattenedList;
    const filter = searchTerm.toLocaleLowerCase();
    return flattenedList.filter(
      (c) =>
        c.name.toLocaleLowerCase().startsWith(filter) ||
        c.city.toLocaleLowerCase().startsWith(filter) ||
        c.address.toLocaleLowerCase().indexOf(filter) >= 0
    );
  }, [flattenedList, searchTerm]);

  const cityChangedHandler = useCallback(
    async (city?: string) => await fetchClients(city),
    [fetchClients]
  );

  return (
    <AdminLayout
      title="Clients"
      allowedRoles={["ManageClients", "SystemAdmin"]}
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box width={"50%"} display={"flex"} gap={1} alignItems="center">
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <Autocomplete
              freeSolo
              id="combo-box-demo"
              options={[]}
              sx={{ width: 300 }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="search"
                  label="Search"
                  name="search"
                  autoComplete="search"
                  autoFocus
                  value={searchTerm}
                  size="small"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchTerm(e.target.value)
                  }
                />
              )}
            />
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={1}
          >
            <TextField
              select
              fullWidth
              name="city"
              label={"City"}
              variant="outlined"
              placeholder={"Select a city"}
              margin="normal"
              SelectProps={{
                native: true,
                IconComponent: KeyboardArrowDown,
              }}
              onChange={(e) => cityChangedHandler(e.target.value)}
              size="small"
            >
              <option value=""></option>
              {cities.map((city, index) => (
                <option key={index} value={city.name}>
                  {city.name}
                </option>
              ))}
            </TextField>
          </Box>
        </Box>

        <Box display={"flex"} alignItems={"center"}>
          <Button
            color="primary"
            variant="contained"
            endIcon={<Add />}
            onClick={() => navigateTo("/clients/add")}
          >
            Add
          </Button>
        </Box>
      </Box>
      <DataTable
        data={filteredClients}
        columns={tableColumns}
        onRowClicked={(row) => navigateTo("/clients/update", { state: row })}
        emptyDisplay={processing ? <CircularProgress size={100} /> : undefined}
      />
    </AdminLayout>
  );
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default Clients;
