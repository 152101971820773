import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, TextField } from "@mui/material";
import { FormikValues } from "formik";

type CashoutProps = {
  errors: FormikValues;
  values: FormikValues;
  touched: FormikValues;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
};
const CashOut = ({
  errors,
  values,
  handleChange,
  touched,
  handleBlur,
}: CashoutProps) => {
  return (
    <>
      <Box py={1}>
        <TextField
          fullWidth
          name="accountName"
          label={"Account Name"}
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.accountName}
          helperText={touched.accountName && errors.accountName}
          error={Boolean(touched.accountName && errors.accountName)}
        />
      </Box>
      <Box py={1}>
        <TextField
          fullWidth
          name="accountNumber"
          label={"Account Number"}
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.accountNumber}
          helperText={touched.accountNumber && errors.accountNumber}
          error={Boolean(touched.accountNumber && errors.accountNumber)}
        />
      </Box>
      <Box py={1}>
        <TextField
          select
          fullWidth
          name={"provider"}
          label={"Provider"}
          variant="outlined"
          placeholder={"Select a provider"}
          SelectProps={{
            native: true,
            IconComponent: KeyboardArrowDown,
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.provider}
          helperText={touched.provider && errors.provider}
          error={Boolean(touched.provider && errors.provider)}
        >
          <option value=""></option>
          <option value="mtn">MTN</option>
          <option value="voda">Vodafone</option>
          <option value="airtel">Airtel</option>
        </TextField>
      </Box>
    </>
  );
};
export default CashOut;
