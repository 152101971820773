import {
  Box,
  Breadcrumbs,
  Button,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AdminLayout from "../admin/layout";
import { DeliveryDiningOutlined } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import useDelivery from "../hooks/useDelivery";
import { useCallback, useEffect, useState } from "react";
import { CenterContent } from "../components/centerContent";

export function DeliverySetupPage() {
  const params = useParams();
  const clientId = params.clientId;

  return (
    <AdminLayout allowedRoles={["SystemAdmin"]}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/clients">
          Clients
        </Link>
        <Typography color="text.primary">
          Setup Delivery <DeliveryDiningOutlined />
        </Typography>
      </Breadcrumbs>
      <DeliveryLocations clientId={clientId} />
    </AdminLayout>
  );
}

type DeliveryLocationsListProps = {
  clientId?: string;
};
function DeliveryLocations(props: DeliveryLocationsListProps) {
  const { clientId } = props;
  const { processing, locations, fetchLocations, createLocation } =
    useDelivery();

  useEffect(() => {
    clientId && fetchLocations(clientId);
  }, [clientId, fetchLocations]);

  const handleSave = useCallback(
    async (clientId: string, data: any) => {
      await createLocation(clientId, data);
    },
    [createLocation]
  );

  if (processing && !locations) {
    return (
      <CenterContent>
        <p>Loading...</p>
      </CenterContent>
    );
  }

  return (
    <Stack width="580px" padding={8}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">Area</Typography>
        <Typography variant="h6">Charge</Typography>
      </Stack>
      {locations?.map((location) => (
        <DeliveryLocationForm
          key={location.name}
          clientId={clientId}
          location={location}
          mode="View"
          onSave={handleSave}
          processing={processing}
        />
      ))}
      <DeliveryLocationForm
        clientId={clientId}
        onSave={handleSave}
        processing={processing}
      />
    </Stack>
  );
}

type DeliveryLocationFormProps = {
  clientId?: string;
  location?: { name: string; charge: number; id?: string };
  mode?: string;
  onSave?: (clientId: string, data: any) => void;
  processing?: boolean;
};

const FormMode = {
  Create: "Create",
  Update: "Update",
  View: "View",
};

function DeliveryLocationForm(props: DeliveryLocationFormProps) {
  const { clientId, location, processing } = props;
  const initialMode = props.mode ?? location ? FormMode.View : FormMode.Create;
  const [name, setName] = useState(location?.name ?? "");
  const [charge, setCharge] = useState(location?.charge ?? "");

  const [mode, setMode] = useState(initialMode);
  let isDirty =
    (location && (name !== location?.name || charge !== location?.charge)) ||
    (!location && (name || charge));

  const save = async () => {
    if (processing) return;
    if (!name) return;
    if (!clientId) return;

    props.onSave && props.onSave(clientId, { name, charge, id: location?.id });
    if (mode === FormMode.Create) {
      setName("");
      setCharge("");
    } else {
      setMode(FormMode.View);
    }
  };

  return (
    <Box m={1}>
      <Stack direction="row" spacing={1} justifyContent="start">
        <Box width="80%">
          <TextField
            placeholder="Area"
            variant="outlined"
            value={name}
            size="small"
            onChange={(e) => setName(e.target.value)}
            fullWidth
            inputProps={{ readOnly: mode === FormMode.View }}
          />
        </Box>
        <TextField
          inputMode="decimal"
          type="number"
          placeholder="Charge"
          variant="outlined"
          size="small"
          value={charge}
          onChange={(e) => setCharge(Number(e.target.value))}
          inputProps={{ readOnly: mode === FormMode.View }}
        />
        {mode === FormMode.View && (
          <Button
            variant="text"
            onClick={() => setMode(FormMode.Update)}
            size="small"
          >
            Change
          </Button>
        )}

        {mode !== FormMode.View && isDirty && (
          <>
            <Button variant="outlined" onClick={save} size="small">
              Save
            </Button>
            <Button
              variant="text"
              onClick={() => setMode(FormMode.View)}
              size="small"
            >
              Cancel
            </Button>
          </>
        )}
      </Stack>
    </Box>
  );
}
