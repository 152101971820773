import { Box, CircularProgress, Container, Typography } from "@mui/material";
import useAuthentication from "../authentication/useAuthentication";
import { CenterContent } from "../components/centerContent";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import AppLayout from "../layout";
import MenuBar from "./menuBar";

type AdminLayoutProps = {
  children: JSX.Element | JSX.Element[];
  title?: string;
  allowedRoles?: string[];
};

export default function AdminLayout(props: AdminLayoutProps) {
  const navigateTo = useNavigate();
  const { loading, user, signOut } = useAuthentication();

  const { children, allowedRoles } = props;

  useEffect(() => {
    if (!user && !loading) {
      navigateTo("/");
    }

    if (user && !loading) {
      if (allowedRoles && !allowedRoles.some((r) => user.groups.includes(r))) {
        navigateTo("/");
      }
    }
  }, [user, loading, allowedRoles, navigateTo]);

  const MenuComponent = useMemo(() => {
    const userMenuItems = [{ text: "Sign out", onClick: signOut }];
    if (user?.groups.includes("SystemAdmin")) {
      userMenuItems.push({
        text: "System Events",
        onClick: async () => navigateTo("/system/events"),
      });
    }
    const otherMenuItems = [];
    if (user?.groups.includes("SystemAdmin")) {
      otherMenuItems.push(
        {
          text: "Clients",
          onClick: () => navigateTo("/clients"),
        },
        {
          text: "Cities",
          onClick: () => navigateTo("/cities"),
        }
      );
    } else if (user?.groups.includes("ManageClients")) {
      otherMenuItems.push({
        text: "Clients",
        onClick: () => navigateTo("/clients"),
      });
    }

    return (
      <MenuBar
        username={user?.attributes?.given_name}
        userMenu={userMenuItems}
        menuItems={otherMenuItems}
      />
    );
  }, [signOut, user?.attributes?.given_name, user?.groups, navigateTo]);

  if (loading) {
    return (
      <AppLayout>
        <CenterContent>
          <CircularProgress />
        </CenterContent>
      </AppLayout>
    );
  }

  return (
    <AppLayout menuComponent={MenuComponent}>
      <Box mt={5}>
        <Container sx={{ backgroundColor: "white", p: 5 }}>
          <Box display={"flex"}>
            <Typography fontSize={30}>{props.title}</Typography>
          </Box>
          {children}
        </Container>
      </Box>
    </AppLayout>
  );
}
