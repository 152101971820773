import {
  Box,
  Chip,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import AdminLayout from "../admin/layout";
import useEvents from "./useEvents";
import Spacing from "../components/space";
import { useEffect, useState } from "react";
import { CenterContent } from "../components/centerContent";

export default function ActivitiesPage() {
  const { events, loadEvents } = useEvents();
  const [appId, setAppId] = useState("POS-API");
  const [eventType, setEventType] = useState("error");

  useEffect(() => {
    loadEvents(appId, eventType);
  }, [loadEvents, appId, eventType]);

  return (
    <AdminLayout allowedRoles={["SystemAdmin"]}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5">Recent Events</Typography>
        <Stack direction="row">
          <Select
            size="small"
            value={appId}
            onChange={(e) => setAppId(e.target.value as string)}
          >
            <MenuItem value="NKAPREPOS">POS App</MenuItem>
            <MenuItem value="POS-API">POS API</MenuItem>
            <MenuItem value="NKAPRE-CORE-API">NKAPRE API</MenuItem>
          </Select>
          <Spacing variant="vertical" />
          <Select
            size="small"
            value={eventType}
            onChange={(e) => setEventType(e.target.value as string)}
          >
            <MenuItem value="error">Handled Exceptions</MenuItem>
            <MenuItem value="exception">Unhandled Exceptions</MenuItem>
            <MenuItem value="warning">Warning</MenuItem>
            <MenuItem value="Payment">Payments</MenuItem>
            <MenuItem value="Product">Products</MenuItem>
            <MenuItem value="">Any</MenuItem>
          </Select>
        </Stack>
      </Stack>
      <EventsListing events={events} />
    </AdminLayout>
  );
}

function EventsListing({ events }: { events: any[] }) {
  if (!events?.length) {
    return (
      <CenterContent>
        <Typography>No events</Typography>
      </CenterContent>
    );
  }

  return (
    <Box>
      {events &&
        events.map((ev: any) => <EventDisplay event={ev} key={ev?.sk} />)}
    </Box>
  );
}

const errorTypes = ["error", "exception", "PaymentFailed"];
function EventDisplay({ event }: { event: any }) {
  if (!event) return <></>;
  const eventDate = new Date(Number(event.data.timestamp * 1000));
  return (
    <Paper style={{ padding: 8, margin: 8, borderBottom: 1 }}>
      <Stack>
        <code
          style={{ backgroundColor: "darkgray", color: "black", padding: 8 }}
        >
          {event.data.detail}
        </code>
        <Stack direction="row">
          <Typography width="20%">{eventDate.toLocaleString()}</Typography>
          <Spacing variant="vertical" />
          <Chip
            label={event.data.eventType}
            variant={
              errorTypes.includes(event.data.eventType) ? "filled" : "outlined"
            }
            color={errorTypes.includes(event.data.eventType) ? "error" : "info"}
          />
        </Stack>
      </Stack>
    </Paper>
  );
}
