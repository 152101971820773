import useHttp from "@fast-ninjas/hooks.use-http";
import useAuthentication from "../authentication/useAuthentication";
import { useCallback, useState } from "react";
import { Logger } from "../common/logger";
import config from "../configLoader";

export default function useDelivery() {
  const [locations, setLocations] = useState<any[]>([]);
  const { getAccessToken } = useAuthentication();
  const { post, get, loading, patch } = useHttp();
  const apiEndpoint = config.apiEndpoint;

  const createAccessHeader = useCallback(
    async () => ({
      Authorization: `Bearer ${await getAccessToken()}`,
    }),
    [getAccessToken]
  );

  const fetchLocations = useCallback(
    async (clientId: string) => {
      const authHeader = await createAccessHeader();
      try {
        const result = await get(
          `${apiEndpoint}/api/delivery/locations/${clientId}`,
          authHeader
        );

        setLocations(result);
        return result;
      } catch (err) {
        Logger.error(`Unable to load locations for ${clientId}`);
      }
      return [];
    },
    [get, apiEndpoint, createAccessHeader]
  );

  const createLocation = useCallback(
    async (clientId: string, data: any) => {
      const authHeader = await createAccessHeader();
      const response = await post(
        `${apiEndpoint}/api/delivery/locations/${clientId}`,
        data,
        authHeader
      );
      if (!response.ok) throw response.statusText;
      const location = await response.json();

      setLocations([
        ...locations.filter((l) => l.id !== location.id),
        location,
      ]);
      return location;
    },
    [createAccessHeader, post, apiEndpoint, locations, setLocations]
  );

  const updateLocation = useCallback(
    async (data: any, clientId: string) => {
      const authHeader = await createAccessHeader();
      const response = await patch(
        `${apiEndpoint}/api/delivery/locations/${clientId}`,
        data,
        authHeader
      );
      if (!response.ok) throw response.statusText;
      return await response.json();
    },
    [createAccessHeader, patch, apiEndpoint]
  );

  return {
    createLocation,
    updateLocation,
    processing: loading,
    fetchLocations,
    locations,
  };
}
